.cursor-pointer{
  cursor: pointer;
}

.processing-node{
  position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    /* bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: #ffffffe3; */
    z-index: 9;
    color: #f8be45;
    font-size: 14px;
}

.modal.show .modal-dialog {
  transform: translate(0, 0%) !important;
}

.pl-20{
  padding-left: 20px;
}
.or-text {
  text-align: center;
  position: relative;
  height: 40px;
  margin-bottom: 10px;
}
.or-text p {
  line-height: 40px;
  margin: 0;
  z-index: 999;
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0 10px;
  background: #fff;
  font-weight: 500;
  color: #676767;
  z-index: 1;
}
.or-text:after {
  content: "";
  height: 2px;
  background: #f5f5f5;
  position: absolute;
  left: 40px;
  right: 40px;
  top: 48%;
  display: block;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
  background-color: #1d253b24 !important;
}

.boolean-input{
  display: block;
  width: 180px;
}