// Here you can add other styles

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
	font-family: "Poppins", sans-serif;
}

.btn {
	outline: 0 !important;
	box-shadow: none !important;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	letter-spacing: 0.5px;
	transform: unset !important;
}

a {
	color: #f18f3c !important;
	font-weight: 400 !important;
}

label {
	font-size: 14px !important;
	letter-spacing: 0.3px;
	padding-left: 2px;
}

.input-group,
.form-group {
	margin-bottom: 15px !important;
}

.form-control {
	font-size: 15px !important;
	font-weight: 400;
	letter-spacing: 0.3px;
	padding: 6px 12px !important;
	border: 1px solid #ced4da !important;

	&:focus {
		border-color: #f18f3c;
	}
}

.themeBtn {
	background-color: #f18f3c;
	border: 1px solid #f18f3c !important;
	background-image: none;
	color: #fff !important;
	font-size: 16px;
	margin: 20px auto 10px;
	width: 280px;
	display: block;
	height: 42px;
	font-weight: 600 !important;
	line-height: 42px;
	padding: 0 12px;

	&:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
	}
}

.themeBtn:focus,
.themeBtn:active,
.themeBtn.active,
.themeBtn:active:focus,
.themeBtn:active:hover,
.themeBtn.active:focus,
.themeBtn.active:hover {
	background-color: #f18f3c !important;
	background-image: none !important;
}

.validation-error {
	color: #f16667;
	font-size: 13px;
	margin-top: 3px;
	padding-left: 5px;
}

.fs-16 {
	font-size: 16px !important;
}

.table-responsive {
	overflow: unset !important;
	overflow-x: auto !important;
	padding-bottom: 0 !important;
}

.table > thead > tr > th {
	font-size: 14px;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 15px 15px 15px !important;

	.copyright {
		float: unset !important;
		padding: 0 10px !important;
	}

	.nav {
		float: unset !important;
		align-self: flex-end;
		padding-left: 10px !important;
	}

	&:after {
		display: none !important;
	}

	a {
		color: #697ba0 !important;
	}

	.lsWebsite {
		color: #31c5c7 !important;
	}
}

.main-panel > .content {
	padding: 78px 20px 15px 30px !important;
	min-height: calc(100vh - 49px) !important;
}

.dropdown-menu .dropdown-item {
	outline: 0 !important;
	color: #9a9a9a !important;
}

.sideMenuLogo {
	width: 165px;
}

.main-panel[data="blue"] {
	border-top: 2px solid #f18f3c;
}

.sidebar[data="blue"] {
	background: linear-gradient(0deg, #fbd449 0%, #f4a03f 100%);
}

.sidebar .nav p {
	font-size: 14px;
	font-weight: 500;
}

.sidebar .nav i {
	margin-right: 8px;
}

.modal-header {
	border-bottom: 1px solid #e9ecef !important;
	padding: 18px 24px 14px !important;
}

.modal-title {
	color: #333 !important;
	font-size: 18px;
	font-weight: 500;
}

.modal .modal-header .close {
	color: #fd5d93 !important;
	padding: 10px 10px 5px !important;
}

.modal-body {
	padding-top: 16px !important;

	.form-control {
		color: #333 !important;
	}
}

.modal-footer {
	padding: 14px 24px 14px !important;
	justify-content: flex-end !important;

	.btn {
		margin-left: 12px !important;
		height: 36px;
		padding: 0 12px !important;
		line-height: 34px !important;
		border: 1px solid #f18f3c !important;
		border-radius: 6px !important;
	}

	.modalBtnCancel {
		background-color: transparent !important;
		border: 1px solid #a5a5a5 !important;
		background-image: none !important;
		color: #a5a5a5 !important;

		&:hover {
			background-color: transparent !important;
			background-image: none !important;
			color: #a5a5a5 !important;
		}
	}

	.modalBtnCancel:focus,
	.modalBtnCancel:active,
	.modalBtnCancel.active,
	.modalBtnCancel:active:focus,
	.modalBtnCancel:active:hover,
	.modalBtnCancel.active:focus,
	.modalBtnCancel.active:hover {
		background-color: transparent !important;
		background-image: none !important;
		color: #a5a5a5 !important;
	}

	.modalBtnSave {
		background-color: #f18f3c !important;
		border: 1px solid #f18f3c !important;
		background-image: none !important;
		color: #fff !important;

		&:hover {
			background-color: #f18f3c !important;
			background-image: none !important;
			color: #fff !important;
		}
	}

	.modalBtnSave:focus,
	.modalBtnSave:active,
	.modalBtnSave.active,
	.modalBtnSave:active:focus,
	.modalBtnSave:active:hover,
	.modalBtnSave.active:focus,
	.modalBtnSave.active:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
}

.companyLogo {
	width: 310px;
	display: block;
	margin: 8px auto 35px;
}

.authFormWrap {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
		0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);

	.card {
		margin-bottom: 0;
		border-radius: 12px;

		label,
		.forgotPassword {
			color: #676767 !important;
		}

		.card-header {
			padding: 22px 25px 0;

			.card-title {
				font-weight: 600;
				color: #f18f3c;
				font-size: 26px;
			}
		}

		.card-body {
			padding: 12px 25px 15px;
		}
	}

	.form-group {
		position: relative;

		.form-control {
			padding-left: 34px !important;
			color: #333 !important;

			&:focus {
				border-color: #f18f3c !important;
			}
		}

		.formIcon {
			position: absolute;
			top: 12px;
			left: 12px;
			font-size: 14px;
			color: #b0b0b0;
		}

		.lockIcon {
			font-size: 15px;
			top: 11.5px;
		}

		.callIcon {
			top: 12.5px;
		}

		.mailIcon {
			top: 11.5px;
		}

		.showPassword {
			position: absolute;
			top: 12px;
			right: 12px;
			color: #a0a0a0;
		}

		.passwordField {
			padding-right: 35px !important;
		}
	}

	.themeBtn {
		margin: 30px auto !important;
	}

	.createAccount {
		color: #808080 !important;
		display: block;
		margin: 0 auto 10px;
		width: 250px;
		text-align: center;
		letter-spacing: 0.3px;

		span {
			color: #f18f3c !important;
			font-weight: 500;
		}
	}
}

.authFooter {
	width: 100%;
	max-width: 500px;
	margin: 12px auto;
	display: flex;
	justify-content: space-between;
	color: #bbb;
	font-size: 14px;

	a {
		color: #f6bb44 !important;
	}
}

.tableFilterWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px !important;

	.form-control {
		border-color: #676767 !important;
		color: #ddd !important;

		&:focus {
			border-color: #f18f3c !important;
		}
	}
}

.addBtn {
	background-color: #f18f3c !important;
	border-color: #f18f3c !important;
	color: #fff !important;
	height: 38px;
	line-height: 38px !important;
	background-image: none !important;
	padding: 0 15px !important;

	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&:active:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.addBtn.active {
	background-color: #f18f3c !important;
	background-image: none !important;
	color: #fff !important;

	&:focus,
	&:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.viewBtn {
	background-color: transparent !important;
	background-image: none !important;
	border: 1px solid #f18f3c !important;
	color: #fff !important;
	height: 32px;
	line-height: 30px !important;
	padding: 0 12px !important;

	&:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.viewBtn:focus,
.viewBtn:active,
.viewBtn.active,
.viewBtn:active:focus,
.viewBtn:active:hover,
.viewBtn.active:focus,
.viewBtn.active:hover {
	background-color: #f18f3c !important;
	background-image: none !important;
	color: #fff !important;
}

.stopBtn {
	background: transparent !important;
	border: 1px solid #f16667 !important;
	height: 32px;
	line-height: 30px !important;
	padding: 0 12px !important;

	&:hover {
		background-color: #f16667 !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.stopBtn:focus,
.stopBtn:active,
.stopBtn.active,
.stopBtn:active:focus,
.stopBtn:active:hover,
.stopBtn.active:focus,
.stopBtn.active:hover {
	background-color: #f16667 !important;
	background-image: none !important;
	color: #fff !important;
}

.licensesList li {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #666;
	border-radius: 0 !important;
	display: flex;
	justify-content: space-between;
	padding: 18px 5px;

	&:first-child {
		padding-top: 15px;
	}

	&:last-child {
		border-bottom: 0;
		padding-bottom: 15px;
	}
}

.userInfo {
	.card-header {
		padding-bottom: 10px !important;
		border-bottom: 1px solid #525252 !important;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.3px;
	}

	.card-footer {
		border-top: 1px solid #525252 !important;
		display: flex;
		justify-content: space-between;
		padding: 20px 15px 22px !important;

		.btn {
			margin: 0 0 0 20px;
			flex-shrink: 0;
		}
	}

	label {
		color: #f5f5f5 !important;
	}
}

.card-chart .card-header {
	.card-category {
		font-size: 15px;
		letter-spacing: 0.3px;
		margin-bottom: 8px !important;
	}

	.card-title i {
		font-size: 22px !important;
		color: #f18f3c;
	}
}

.dashboardCard {
	text-align: center;
	cursor: pointer;

	i {
		font-size: 36px;
		color: #f18f3c;
	}

	h3 {
		font-size: 28px;
		margin: 15px 0 10px;
		font-weight: 600;
	}

	p {
		margin-bottom: 5px;
		font-size: 15px;
		letter-spacing: 0.3px;
		font-weight: 500;
	}
}

.custom-control-label::before,
.custom-control-label::after {
	border: #adb5bd solid 1px;
	top: -8px !important;
	width: 1rem !important;
	height: 1rem !important;
}

.custom-control-label::after {
	border: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
	background-color: #f18f3c;
	border-color: #f18f3c;
}

.saveBtn {
	background-color: #f18f3c !important;
	border: 1px solid #f18f3c !important;
	background-image: none !important;
	color: #fff !important;
	font-size: 16px !important;

	&:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
		color: #fff !important;
	}
}

.saveBtn:focus,
.saveBtn:active,
.saveBtn.active,
.saveBtn:active:focus,
.saveBtn:active:hover,
.saveBtn.active:focus,
.saveBtn.active:hover {
	background-color: #f18f3c !important;
	background-image: none !important;
	color: #fff !important;
}

.cancelBtn {
	background-color: transparent !important;
	border: 1px solid #a5a5a5 !important;
	background-image: none !important;
	color: #a5a5a5 !important;
	font-size: 16px !important;

	&:hover {
		background-color: transparent !important;
		background-image: none !important;
		color: #a5a5a5 !important;
	}
}

.cancelBtn:focus,
.cancelBtn:active,
.cancelBtn.active,
.cancelBtn:active:focus,
.cancelBtn:active:hover,
.cancelBtn.active:focus,
.cancelBtn.active:hover {
	background-color: transparent !important;
	background-image: none !important;
	color: #a5a5a5 !important;
}

.assetImg {
	height: 50px;
	width: 50px;
	object-fit: cover;
	border: 1px solid #909090;
	padding: 1px;
	border-radius: 6px;
	margin-right: 8px;
}

.newLabBtn {
	position: fixed;
	bottom: 55px;
	right: 20px;
	padding: 0 14px;
	height: 40px;
	line-height: 40px;
	background-color: #f18f3c;
	border: 1px solid #f18f3c !important;
	background-image: none;
	font-size: 15px;

	&:hover {
		background-color: #f18f3c !important;
		background-image: none !important;
	}
}

.newLabBtn:focus,
.newLabBtn:active,
.newLabBtn.active,
.newLabBtn:active:focus,
.newLabBtn:active:hover,
.newLabBtn.active:focus,
.newLabBtn.active:hover {
	background-color: #f18f3c !important;
	background-image: none !important;
}

.tableInModal {
	margin-bottom: 0;
}

.tableInModal > thead > tr > th {
	color: #333 !important;
	border-bottom: 2px solid #dee2e6;
	font-size: 14px;
	letter-spacing: 0.3px;
}

.tableInModal > tbody > tr > td {
	color: #676767 !important;
	border-top: 1px solid #dee2e6;
}

.tableInModal {
	.viewBtn {
		color: #f18f3c !important;

		&:hover {
			color: #fff !important;
		}
	}

	.viewBtn:focus,
	.viewBtn:active,
	.viewBtn.active,
	.viewBtn:active:focus,
	.viewBtn:active:hover,
	.viewBtn.active:focus,
	.viewBtn.active:hover {
		color: #fff !important;
	}

	.stopBtn {
		color: #f16667 !important;

		&:hover {
			color: #fff !important;
		}
	}

	.stopBtn:focus,
	.stopBtn:active,
	.stopBtn.active,
	.stopBtn:active:focus,
	.stopBtn:active:hover,
	.stopBtn.active:focus,
	.stopBtn.active:hover {
		color: #fff !important;
	}
}

.isAdminCheck .custom-control-label::before,
.isAdminCheck .custom-control-label::after {
	top: 5px !important;
}

.isAdminWrap {
	label {
		margin-bottom: 0;
	}

	.isAdminCheck .custom-control-label::before,
	.isAdminCheck .custom-control-label::after {
		top: 2px !important;
	}
}
// .switch-toggle-button{
// 	font-size: 10px;
// 	.react-switch-handle{

// 		// transform: translateX(20px);
// 		// width:40px
// 	}
// 	.react-switch-bg{
// 		// padding:0 5px;
// // width:40px !important;
// 	}
// }

// Design Lab Side Menu CSS
.designLabSidebar .nav {
	margin-top: 50px;
}

#sidebarMenu {
	position: absolute;
	left: 0;
	transform: translateX(-180px);
	transition: transform 250ms ease-in-out;
	z-index: 98;
	overflow: auto;
	scrollbar-color: rgba(103, 112, 210, 0.8) #bbb;
	scrollbar-width: thin;
}

#sidebarMenu::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	border-radius: 0;
	background-color: #bbb;
}

#sidebarMenu::-webkit-scrollbar {
	width: 8px;
	background-color: #bbb;
}

#sidebarMenu::-webkit-scrollbar-thumb {
	border-radius: 0;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
	background-color: rgba(103, 112, 210, 0.8);
}

.sidebarMenuInner li {
	list-style: none;
	color: #fff;
	padding: 11px 16px;
	font-size: 14px;
	cursor: pointer;
	position: relative;
	overflow: auto;
}

.designLabSidebar .sidebarMenuInner li i {
	margin-right: 3px;
}

.designLabSidebar .sidebarMenuInner li img {
	margin-right: 5px;
}

#sidebarMenu .sidebarMenuInner li p {
	display: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
	transform: translateX(0px);
	transition: transform 250ms ease-in-out;
}

input[type="checkbox"]:checked ~ #sidebarMenu .sidebarMenuInner li {
	padding-right: 21px;
}

input[type="checkbox"]:checked ~ #sidebarMenu .sidebarMenuInner li div {
	justify-content: flex-start !important;
}

input[type="checkbox"]:checked ~ #sidebarMenu .sidebarMenuInner li p {
	display: initial;
}

input[type="checkbox"]:checked ~ #sidebarMenu .sidebarMenuInner li i {
	font-size: 23px;
	margin-right: 10px;
}

input[type="checkbox"]:checked ~ #sidebarMenu .sidebarMenuInner li img {
	margin-right: 13px;
}

.designLabSidebarWrapper input[type="checkbox"] {
	transition: all 0.3s;
	box-sizing: border-box;
	display: none;
}

.sidebarIconToggle {
	transition: all 0.3s;
	box-sizing: border-box;
	cursor: pointer;
	position: absolute;
	z-index: 100;
	height: 100%;
	width: 100%;
	top: 100px;
	left: 23px;
	height: 22px;
	width: 22px;
	margin-bottom: 0;
}

input[type="checkbox"]:checked + .sidebarIconToggle {
	left: 213px;
	top: 95px;
}

.spinner {
	transition: all 0.3s;
	box-sizing: border-box;
	position: absolute;
	height: 3px;
	width: 100%;
	background-color: #fff;
}

.horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	opacity: 1;
	margin-top: 3px;
}

.diagonal.part-1 {
	position: relative;
	transition: all 0.3s;
	box-sizing: border-box;
	float: left;
	transform: rotate(0deg);
	margin-top: 1px;
}

.diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	transform: rotate(0deg);
	margin-top: 3px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	opacity: 0;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(135deg);
	margin-top: 9px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(-135deg);
	margin-top: -9px;
}

.sideMenuTooltip .tooltip.show {
	left: 125px !important;
	font-size: 14px;
}

.main-panel > .designLabSideMenuClosedPadding {
	padding-left: 85px !important;
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
	.main-panel > .content {
		padding-left: 15px !important;
	}

	// 	.navbar-expand-lg .navbar-right {
	//     display: none;
	//   }
	//   .navbar-expand-lg .navbar-collapse {
	//     display: flex !important;
	//     flex-basis: auto;
	//   }
	//   .navbar-expand-lg .navbar-nav .nav-link {
	//     padding-right: 0.5rem;
	//     padding-left: 0.5rem;
	//   }
	//   .navbar .navbar-nav .dropdown {
	//     margin: 0;
	//   }
	//   .navbar .caret {
	//     position: absolute;
	//     left: 80%;
	//     top: 55%;
	//     margin-left: 0;
	//   }
	//   .dropdown-toggle:after {
	//     display: none;
	//   }
	//   .navbar .navbar-nav .dropdown .nav-link {
	//     padding-bottom: 0.5rem;
	//   }
	//   .navbar-expand-lg .navbar-nav .dropdown-menu {
	//     position: absolute !important;
	//   }
	//   .navbar .dropdown.show .dropdown-menu, .navbar .dropdown .dropdown-menu {
	//     background-color: #fff;
	//     box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
	//     transition: all 150ms linear;
	//     margin: 0.125rem 0 0;
	//   }
	//   .navbar-nav li {
	//     padding: 0 10px !important;
	//   }
	//   .navbar .navbar-nav .dropdown .dropdown-menu .dropdown-item {
	//     margin-top: 5px;
	//   }
	//   .dropdown-menu:before {
	//     display: inline-block !important;
	//  }
}

// @media (min-width: 992px) {
//   .main-panel {
//     width: 100%;
//   }
//   .nav-open .main-panel {
//     right: 0;
//     transform: translate3d(260px, 0, 0);
//   }
//   .main-panel > .content {
//     padding-left: 15px !important;
//   }
//   .navbar-expand-lg .navbar-toggle .navbar-toggler {
//     display: inline;
//   }
//   .navbar .navbar-toggler-bar {
//     display: block;
//     position: relative;
//     width: 22px;
//     height: 1px;
//     border-radius: 1px;
//     background: #FFFFFF;
//   }
//   .navbar .navbar-brand {
//     margin-left: 20px;
//     position: relative;
//   }
//   .bar1, .bar2, .bar3 {
//     outline: 1px solid transparent;
//   }
//   .bar1 {
//     top: 0px;
//     animation: topbar-back 500ms 0s;
//     animation-fill-mode: forwards;
//   }
//   .navbar .navbar-toggler-bar.bar2 {
//     width: 17px;
//     transition: width .2s linear;
//   }
//   .navbar .navbar-toggler-bar + .navbar-toggler-bar {
//     margin-top: 7px;
//   }
//   .bar2 {
//     opacity: 1;
//   }
//   .bar3 {
//     bottom: 0px;
//     animation: bottombar-back 500ms 0s;
//     animation-fill-mode: forwards;
//   }
//   .sidebar {
//     position: fixed;
//     display: block;
//     top: 0;
//     height: 100%;
//     width: 260px;
//     right: auto;
//     left: 0;
//     margin: 0;
//     border-radius: 0;
//     z-index: 1032;
//     visibility: visible;
//     overflow-y: visible;
//     padding: 0;
//     transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
//     transform: translate3d(-260px, 0, 0);
//   }
//   .nav-open .sidebar {
//     transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
//     transform: translate3d(0px, 0, 0);
//   }
// }

.uploadProfile {
	background: #fff !important;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3px;
	cursor: pointer;
	width: 133px;
	height: 133px;
	border-radius: 6px;
	display: block;
	margin: 27px auto 15px;
	position: relative;
	box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);

	img {
		height: 125px;
		width: 125px;
		border-radius: 4px;
		object-fit: contain;
	}

	i {
		font-size: 16px;
		color: #999;
		width: 32px;
		height: 28px;
		line-height: 26px;
		position: absolute;
		bottom: -13px;
		left: 50.5px;
		background-color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.1);
		text-align: center;
		border-radius: 6px;
	}
}
.checkBox {
	width: 20px;
	height: 20px;
	margin-right: 15px;
	cursor: pointer;
}

input[type="checkbox"]:not(:checked) ~ #sidebarMenu .zoom-slider-wrap {
	display: none;
}

.truncate {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 150px;
}

.modal-content {
	position: relative;
}

.resizable-container {
	width: 100% !important;
}

.page_inner_header {
	background-color: #171717;
	padding: 15px;
	border-radius: 6px;
	.page-title {
		h1 {
			margin-bottom: 0;
			font-size: 18px;
			font-weight: 500;
		}
	}
}

.searchBox {
	border: 1px solid #ced4da;
	border-radius: 0.4285rem;
	padding: 4px;
	box-sizing: border-box;
	input {
		border: none !important;
		height: 32px;
	}
	.input-group-append {
		border: none;
		width: 32px;
		height: 32px;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background-color: #292929;
	}
}

.navbar.navbar-transparent {
	background: transparent !important;
}
.badge {
	font-weight: 400;
	padding: 6px 10px;
	font-size: 13px;
}
.badge-success {
	color: #31b27c;
	background-color: #5bd19f33;
}
.badge-warning {
	color: #fdb927;
	background-color: #fdb92733;
}
.badge-danger {
	background: #ff424133;
	color: #ff4241 !important;
}

.pagination {
	justify-content: end;
	li {
		&.active {
			a {
				color: #fff !important;
			}
		}
		a {
			&:hover {
				color: #fff !important;
			}
		}
	}
}

.card {
	input[type="date"]::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}
}

.navbar_list {
	li {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.withUpload {
	.upload_Input {
		input {
			display: none;
		}
		label {
			background: #f18f3c !important;
			padding: 8px 30px;
			border-radius: 10px;
			margin-left: 10px;
		}
	}
}
